// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contato-recebido-index-js": () => import("./../../../src/pages/contato-recebido/index.js" /* webpackChunkName: "component---src-pages-contato-recebido-index-js" */),
  "component---src-pages-fundos-index-js": () => import("./../../../src/pages/fundos/index.js" /* webpackChunkName: "component---src-pages-fundos-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-obrigado-index-js": () => import("./../../../src/pages/obrigado/index.js" /* webpackChunkName: "component---src-pages-obrigado-index-js" */),
  "component---src-pages-obrigado-planilha-index-js": () => import("./../../../src/pages/obrigado-planilha/index.js" /* webpackChunkName: "component---src-pages-obrigado-planilha-index-js" */),
  "component---src-pages-planilha-index-js": () => import("./../../../src/pages/planilha/index.js" /* webpackChunkName: "component---src-pages-planilha-index-js" */)
}

